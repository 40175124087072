import React from "react"
import { Link } from "gatsby"
import { Row, Col, Container, Breadcrumb, Image } from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import LuteinComplexMobile from "./../../images/lut-virusi-eye-virus-banner-hd.jpg"
import LuteinComplexTablet from "./../../images/lut-virusi-eye-virus-banner-hd.jpg"
import LuteinComplexDesktop from "./../../images/lut-virusi-eye-virus-banner-hd.jpg"
import LuteinComplexXL from "./../../images/lut-virusi-eye-virus-banner-hd.jpg"

import DrAtanasova from "./../../images/lut-virusi-d-r-atanasova.jpg"
import DrTaneva from "./../../images/lut-virusi-d-r-taneva.jpg"
import VeneraDimitrova from "./../../images/lut-virusi-venera-dimitrova.jpg"
import lutZinc from "./../../images/lut-virusi-lutein-zinc.jpg"
import capsule from "./../../images/lut-virusi-capsule.png"
import lutein from "./../../images/lut-virusi-lutein-complex.jpg"
import warningSign from "./../../images/lut-virusi-warning-sign.svg"

const LuteinVirusi = () => (
  <Layout backButton={true} pageInfo={{ pageName: "lutein-virusi" }}>
    <Seo title="Вирусите ни атакуват и през очите" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/articles">СТАТИИ</Link>
            </li>
            <Breadcrumb.Item active>
              ВИРУСИТЕ НИ АТАКУВАТ И ПРЕЗ ОЧИТЕ
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Вирусите ни атакуват и през очите"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={12} className="">
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Вирусите ни атакуват и през очите
          </h1>
          <h2 className="font-light">
            <em>
              Адекватната грижа за зрителните ни органи
              <br /> може да предотврати инвазията
            </em>
          </h2>
        </Col>
        <Col sm={12}>
          <p>
            Знаете ли, че очите, също както устата и носа, са портал за
            вирусите, през който те нахлуват и са в състояние да завземат тялото
            ни? Така е, защото болестотворните агенти обожават топлина и влага,
            а именно тези благоприятни условия им предлагат всички наши органи,
            които са в пряк контакт с околния свят и са снабдени с лигавица.
            Затова не е случайно, че за да се предотврати нежеланата инвазия,
            задължителните мерки включват адекватната грижа за здравето на очите
            и подсилване на тяхната преградна способност.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={7}>
          <h4>Скрити зад бронирани прозорци</h4>
          <p>
            Съзрели опасната пробойна в защитната стена на човешкия организъм,
            френски учени разработили специализирана формула за очно здраве,
            която не само подобрява зрението, но и служи като своеобразно
            „брониране“ на нашите прозорци към света – очите – правейки ги
            неприкосновена територия за нашествениците. Основана върху
            комбинацията на природните вещества, чиито укрепващ и оздравителен
            ефект е медицински потвърден, експертната формулировка, позната у
            нас с името ЛУТЕИН КОМПЛЕКС ПЛЮС, представлява комплекс от лутеин,
            зеаксантин и екстракт от черна боровинка плюс точно оптималните
            количества цинк, витамин С и витамин Е, необходими на зрял индивид.
            Именно въз основа на това той има двойно действие – подобрява
            способността за виждане и същевременно стабилизира защитната бариера
            на очите срещу вирусите, което го прави особено ценен в рискови
            ситуации.
          </p>
        </Col>
        <Col sm={5}>
          <Image className="w-100" src={lutZinc} />
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <h4>Ефект на момента</h4>
          <Image className="w-25 p-3 float-left" src={capsule} />
          <p>
            Стремейки се към максимума, що се отнася до ефективност и бързина на
            действие, в производството на ЛУТЕИН КОМПЛЕКС ПЛЮС е приложена
            върховата технология LICAPS™, при която всички съставки са в течен
            маслен вид. Това не само запазва тяхната естествена биологична
            активност непокътната, но и многократно подобрява усвояването им в
            организма, което осигурява осезаем резултат още в началото на
            приема. Нещо, което стандартните продукти на прах не биха могли да
            постигнат, дори след месеци на непрекъсната употреба. Показателен за
            това е не само личният опит на онези, които вече са го изпробвали и
            са познали в негово лице верен приятел, който е укрепил състоянието
            им, но и професионалните наблюдения на лекарите и фармацевтите.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <h4>Приятел в нужда се познава</h4>
          <p>
            Обединявайки в себе си цялото медицинско познание в областта,{" "}
            <Link to="/lutein-complex">ЛУТЕИН КОМПЛЕКС ПЛЮС</Link> няма аналог
            на пазара по състав и фармакологична форма – течност в капсула.
            Всяка една от съставките му има доказан ефект върху здравето на
            очите и имунитета, а в комбинация те взаимно усилват ефектите си,
            така че да осигурят най-доброто за организма. Проведените клинични
            проучвания показват, че той помага при оплаквания от замъглена и
            неясна картина. С подхранващото си действие той е навременна помощ
            при сухота в очите или при старческо отслабване на зрението. Цинкът
            и витамините С и Е, вложени в ЛУТЕИН КОМПЛЕКС ПЛЮС, са всеизвестни
            със своя имуностимулиращ ефект и тъкмо на тях той дължи своите
            протективни свойства и съумява да държи враговете ни настрани.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col className="alert alert-secondary">
          <Image
            style={{
              margin: `1rem`,
              width: `80px`,
              float: `left`,
            }}
            src={warningSign}
          />
          <h4 className="warning-text">
            <strong>Цинкът</strong> като убиец на вируси
          </h4>
          <p className="warning-text">
            Специалистите препоръчват приема на цинк заради неговия ефект върху
            имунната система и общите лечебни процеси в организма. Той спомага
            активацията на Т-помощните клетки, които отиват на лов за вирусите,
            попаднали в нашия организъм. Въпреки че огнището на болестта не може
            да бъде предотвратено, е наблюдавано, че цинкът помага симптомите
            при настинка и вирусни инфекции да отшумяват по-бързо.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={3}>
          <Image className="w-100 mb-4" src={DrAtanasova} fluid />
        </Col>
        <Col sm={9}>
          <h5>
            <strong>Лутеин Комплекс Плюс помага при замъглена картина</strong>
          </h5>
          <p>
            „Препоръчвам ЛУТЕИН КОМПЛЕКС ПЛЮС, тъй като той съдържа комплекс от
            най-добрите съставки и екстракти в грижата за очите в концентрирани
            количества. Приемайте ЛУТЕИН КОМПЛЕКС ПЛЮС по 1 капсула дневно поне
            3 месеца. На лицата в риск от развитие на очни проблеми, както и на
            страдащите от такива, препоръчвам да провеждат ежегодно поне по един
            тримесечен терапевтичен курс с прием на ЛУТЕИН КОМПЛЕКС ПЛЮС.“
          </p>

          <p>
            <i>Д-р Василка Атанасова, офталмолог, Пловдив</i>
          </p>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={9}>
          <h5>
            <strong>
              Препоръчвам Лутеин Комплекс Плюс при зрителна умора и сухота в
              очите
            </strong>
          </h5>
          <p>
            „Синдромът на сухото око е многофакторно заболяване на сълзите и
            очната повърхност. Води до дискомфорт, разкъсване на слъзния филм,
            увреждане на очната повърхност и зрителни нарушения. След
            40–50-годишна възраст слъзната секреция намалява. Чести причини са
            начинът на живот, приемът на лекарства, възпалителни процеси, имунни
            дефицити.
          </p>
          <p>
            Всички сухи и уморени очи се нуждаят от защита! Моята препоръка в
            тези случаи е насочена към прием на продукта{" "}
            <Link to="/lutein-complex">ЛУТЕИН КОМПЛЕКС ПЛЮС</Link>, тъй като той
            има редица благотворни ефекти върху здравето на очите: съдейства за
            намаляване на умората в очите, за подобряване на зрителната острота
            и нощното виждане.
          </p>
        </Col>

        <Col sm={3}>
          <Image className="w-100 mb-3" src={DrTaneva} fluid />
        </Col>
        <Col>
          <p>
            ЛУТЕИН КОМПЛЕКС ПЛЮС е добре да бъде приеман поне 3 месеца и се
            препоръчва при лица:
          </p>
          <ul>
            <li>Прекарващи часове пред компютър</li>
            <li>Работещи на изкуствено осветление</li>
            <li>Фамилно обременени с очни смущения.“</li>
          </ul>

          <p>
            <i>
              Д-р Нелидия Танева, офталмолог, частен кабинет в бл. 383, ж.к.
              Младост 3, София
            </i>
          </p>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={3}>
          <Image className="w-100" src={VeneraDimitrova} fluid />
        </Col>
        <Col sm={9}>
          <h5>
            <strong>ЛУТЕИН КОМПЛЕКС ПЛЮС подобрява зрението</strong>
          </h5>
          <p>
            „В практиката си като магистър-фармацевт ежедневно се срещам с
            пациенти с различни проблеми със зрението. По-младите, работата на
            които е свързана с компютри, се оплакват най-често от дискомфорт и
            сухота в очите, а възрастните имат много по-сериозни проблеми,
            свързани със замъгляване и отслабване на зрението. На всички тях
            препоръчвам ЛУТЕИН КОМПЛЕКС ПЛЮС. Това е френски продукт, произведен
            по специална технология, която гарантира пълното усвояване на
            съставките от организма. Улеснение за пациента е и приемът на ЛУТЕИН
            КОМПЛЕКС ПЛЮС – по 1 капсула дневно в продължение на най-малко 3
            месеца. Затова го препоръчвам ежедневно.“
          </p>
          <p>
            <i>
              Венера Димитрова, маг.-фармацевт, аптека „Сейба Кауфланд“, кв.
              Надежда, София
            </i>
          </p>
        </Col>
      </Row>
      <Row className="product-container">
        <Col md={{ span: 8, offset: 2 }}>
          <Link to="/lutein-complex">
            <Image className="w-100" src={lutein} />
          </Link>
        </Col>
      </Row>
      <Row className="product-container">
        <Col>
          <p>
            Можете да откриете ЛУТЕИН КОМПЛЕКС ПЛЮС в най-близката до вас
            аптека.
          </p>
          <p>
            Телефон за консултация и поръчка:{" "}
            <a href="tel:029446006">02 944 60 06</a>
          </p>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <h3>
            <Link to="/lutein-complex">
              Повече информация за ЛУТЕИН КОМПЛЕКС ПЛЮС можете да намерите тук.
            </Link>
          </h3>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <p>
            *Да не се превишава препоръчваната дневна доза! Продуктът да не се
            използва като заместител на разнообразното хранене!
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default LuteinVirusi
